<template>
  <div class="container">
    <el-button @click="$router.back(-1)" size="medium" type="primary"
      >返回</el-button
    >
    <el-form style="width: 90%">
      <p>个人信息</p>
      <el-form-item label="邮箱">
        <el-input v-model="userInfo.user_email"></el-input>
      </el-form-item>
      <el-form-item label="企业微信">
        <el-input v-model="userInfo.wechat_work_id"></el-input>
      </el-form-item>
      <el-form-item label="工作性质">
        <el-select v-model="userInfo.user_employment" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="密码">
                <el-input v-model="userInfo.user_pwd"></el-input>
            </el-form-item> -->
      <el-form-item label="姓">
        <el-input v-model="userInfo.user_lastName"></el-input>
      </el-form-item>
      <el-form-item label="名">
        <el-input v-model="userInfo.user_givenName"></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model="userInfo.user_mobile"></el-input>
      </el-form-item>
      <el-form-item label="英文名称">
        <el-input v-model="userInfo.user_enName"></el-input>
      </el-form-item>
      <el-form-item label="国家">
        <el-select v-model="userInfo.user_country">
          <el-option
            v-for="item in countrys"
            :key="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="城市">
        <el-select v-model="userInfo.user_city">
          <el-option
            v-for="item in citys"
            :key="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="工作日志模板">
        <el-select v-model="userInfo.employee_sheet_template_id">
          <el-option
            v-for="item in templates"
            :key="item._id.$id"
            :value="item._id.$id"
            :label="item.template_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属部门">
        <el-select v-model="userInfo.employee_department" placeholder="请选择">
          <el-option label="总办" value="总办"></el-option>
          <el-option label="策划部" value="策划部"></el-option>
          <el-option label="品牌部" value="品牌部"></el-option>
          <el-option label="研发部" value="研发部"></el-option>
          <el-option label="执行部" value="执行部"></el-option>
          <el-option label="财务部" value="财务部"></el-option>
          <el-option label="设计部" value="设计部"></el-option>
          <el-option label="人事部" value="人事部"></el-option>
          <el-option label="前端部" value="前端部"></el-option>
          <el-option label="技术部" value="技术部"></el-option>
          <el-option label="渠道开发部" value="渠道开发部"></el-option>

          <!-- <el-option label="行政人事部" value="行政人事部"></el-option>
                    <el-option label="财务部" value="财务部"></el-option>
                    <el-option label="信息管理部" value="信息管理部"></el-option>
                    <el-option label="市场营销部" value="市场营销部"></el-option>
                    <el-option label="渠道沟通部" value="渠道沟通部"></el-option>
                    <el-option label="客户运营部" value="客户运营部"></el-option>
                    <el-option label="教研部" value="教研部"></el-option>
                    <el-option label="业务开发部" value="业务开发部"></el-option>
                    <el-option label="技术研发中心" value="技术研发中心"></el-option>
                    <el-option label="项目运营部" value="项目运营部"></el-option>
                    <el-option label="品牌市场" value="品牌市场"></el-option>
                    <el-option label="设计部" value="设计部"></el-option> -->
        </el-select>
      </el-form-item>
      <el-form-item label="管理部门">
        <el-input v-model="userInfo.employee_management"></el-input>
      </el-form-item>
      <el-form-item label="管理职称">
        <el-select
          v-model="userInfo.employee_management_territory"
          placeholder="请选择"
        >
          <el-option label="经理（预备）" value="经理（预备）"></el-option>
          <el-option label="经理" value="经理"></el-option>
          <el-option
            label="高级经理（预备）"
            value="高级经理（预备）"
          ></el-option>
          <el-option label="高级经理" value="高级经理"></el-option>
          <el-option label="总监（预备）" value="总监（预备）"></el-option>
          <el-option label="总监" value="总监"></el-option>
          <el-option
            label="高级总监（预备）"
            value="高级总监（预备）"
          ></el-option>
          <el-option label="高级总监" value="高级总监"></el-option>
          <el-option label="副总裁（预备）" value="副总裁（预备）"></el-option>
          <el-option label="副总裁" value="副总裁"></el-option>
          <el-option label="总裁" value="总裁"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="在职状态">
        <el-select
          v-model="userInfo.employee_active_status"
          placeholder="请选择"
        >
          <el-option value="在职" label="在职"></el-option>
          <el-option value="离职" label="离职"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="所属BU">
        <el-select
          v-model="userBUNames"
          multiple
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="item in userBUs"
            :key="item.name"
            :value="item.name"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="下属列表">
        <el-select
          v-model="teammember_ids"
          multiple
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="item in users"
            :key="item._id.$id"
            :value="item._id.$id"
            :label="item.user_lastName + item.user_givenName"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="本地社保连续缴纳起始时间">
        <el-date-picker
          v-model="insurance"
          type="date"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="所属公司">
        <el-select v-model="userInfo.bank_id">
          <el-option
            v-for="item in banks"
            :key="item._id.$id"
            :value="item._id.$id"
            :label="item.bank_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="入职时间">
        <el-date-picker
          v-model="work_start_time"
          type="date"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="workshop评价权限">
        <el-select v-model="userInfo.isSmartAdmin" placeholder="请选择">
          <el-option value="yes" label="是"></el-option>
          <el-option value="no" label="否"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="12个月全勤奖计算日">
        <el-date-picker
          v-model="attendance_bonus_time"
          type="date"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="常态化办公星期">
        <el-select v-model="userInfo.atHomeWeek" placeholder="请选择">
          <el-option value="星期二" label="星期二"></el-option>
          <el-option value="星期三" label="星期三"></el-option>
          <el-option value="星期四" label="星期四"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="是否内部培训师">
        <el-select v-model="userInfo.employee_trainer" placeholder="请选择">
          <el-option value="否" label="否"></el-option>
          <el-option value="是" label="是"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="工作职责">
        <el-input
          v-model="userInfo.responsibility"
          type="textarea"
          :rows="4"
        ></el-input>
      </el-form-item>
      <el-form-item label="是否邮件系统用户">
        <el-select v-model="userInfo.isEmailUser" placeholder="请选择">
          <el-option value="YES" label="YES"></el-option>
          <el-option value="NO" label="NO"></el-option>
        </el-select>
      </el-form-item>
      
    </el-form>
    <el-button @click="updateUser" type="primary" style="margin-top: 20px"
      >更新</el-button
    >
    <el-button @click="refreshUserPwd">重置密码</el-button>

    <h4>头像</h4>

    <img style="width: 60px; height: 60px" :src="headPicUrl" />

    <el-upload
      class="upload-demo"
      action=""
      :limit="1"
      :before-upload="
        (file) => {
          beforeFileUploadHead(file);
        }
      "
    >
      <el-button class="upload" style="width: 120px" :loading="fileLoading">
        上传头像
      </el-button>
    </el-upload>
    <!-- <div>
            <input id="file" ref="file" type="file" @change="uploadFile" class="hidden"/>
            <el-button type="primary" size="medium">
                <i class="el-icon-plus"></i> 上传头像
            </el-button>
        </div> -->
    <!-- <el-button  style="margin-left: 20px;" type="primary" @click="updateHeadpic">上传</el-button> -->

    <h4>汇报对象列表</h4>
    <el-button type="primary" size="small" @click="add">添加汇报对象</el-button>
    <el-table :data="supervisers">
      <el-table-column label="姓名">
        <template slot-scope="scope">
          {{ scope.row.user_lastName + scope.row.user_givenName }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="small"
            @click="remove(scope.row._id.$id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <h4>专业职称</h4>
    <el-button type="primary" size="small" @click="addTerritory"
      >添加专业职称</el-button
    >
    <el-table :data="territorys">
      <el-table-column label="职称">
        <template slot-scope="scope">
          {{ scope.row.employee_territory }}
        </template>
      </el-table-column>
      <el-table-column label="级别">
        <template slot-scope="scope">
          {{ scope.row.employee_territory_level }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="small"
            @click="changeTerritory(scope.$index)"
            >修改</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="removeTerritory(scope.$index)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <h4>设备列表</h4>
    <el-button type="primary" size="small" @click="showEquipmentClick"
      >添加设备</el-button
    >
    <el-table :data="equipments">
      <el-table-column label="设备名称">
        <template slot-scope="scope">
          {{ scope.row.equipment_name }}
        </template>
      </el-table-column>
      <el-table-column label="领取时间">
        <template slot-scope="scope">
          {{ scope.row.equipment_time }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="small"
            @click="deleteEquipment(scope.row._id.$id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <h4>劳动合同签署列表</h4>
    <el-button type="primary" size="small" @click="addContract"
      >添加劳动合同签署</el-button
    >
    <el-table :data="contracts">
      <el-table-column label="名称">
        <template slot-scope="scope">
          {{ scope.row.contract_name }}
        </template>
      </el-table-column>
      <el-table-column label="签署时间">
        <template slot-scope="scope">
          {{ formateTime(scope.row.signature_time) }}
        </template>
      </el-table-column>
      <el-table-column label="开始时间">
        <template slot-scope="scope">
          {{ formateTime(scope.row.begin_time) }}
        </template>
      </el-table-column>
      <el-table-column label="结束时间">
        <template slot-scope="scope">
          {{ formateTime(scope.row.end_time) }}
        </template>
      </el-table-column>
      <el-table-column label="附件">
        <template slot-scope="scope">
          <a v-for="item in scope.row.file_url" :href="item.url">{{
            item.name
          }}</a>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="small"
            @click="changeContract(scope.row)"
            >修改</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="removeContract(scope.row._id.$id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <h4>工作日志列表</h4>
    <el-table :data="sheets" style="width: 100%">
      <el-table-column label="编号" width="100px">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="日期" width="200px">
        <template slot-scope="scope">
          {{ formateTime(scope.row.work_time) }}（{{ formateWeek(scope.row.work_time) }}）
        </template>
      </el-table-column>
      <el-table-column
        prop="sheet_work_ontime"
        label="上班时间"
        width="80px"
      ></el-table-column>
      <el-table-column
        prop="sheet_work_offtime"
        label="下班时间"
        width="80px"
      ></el-table-column>

      <el-table-column
        prop="sheet_work_timelen"
        label="时长"
        width="80px"
      ></el-table-column>
      <el-table-column
        label="加班时长"
        prop="sheet_parttime_work_timelen"
        width="80"
      ></el-table-column>
      <el-table-column label="工作内容">
        <template slot-scope="scope">
          <ul>
            <li v-for="item in scope.row.workContent" :key="item.key">
              {{ item.key }}:{{ item.value }}
            </li>
            <li>加班内容:{{ scope.row.sheet_parttime_work_content }}</li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column
        prop="sheet_work_comp"
        label="报酬"
        width="100px"
      ></el-table-column>
    </el-table>

    <el-dialog :visible.sync="showAdd" title="选择汇报对象">
      <el-select v-model="selectSuperviser">
        <el-option
          v-for="item in users"
          :key="item._id.$id"
          :label="item.user_lastName + item.user_givenName"
          :value="item._id.$id"
        ></el-option>
      </el-select>
      <div style="margin-top: 20px">
        <el-button type="primary" @click="addSuperviserToUser">确认</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="showAddTerritory" title="添加专业职称">
      <h4>职称</h4>
      <div>
        <el-input v-model="employee_territory"></el-input>
      </div>
      <h4>级别</h4>
      <el-select v-model="employee_territory_level" placeholder="请选择">
        <el-option label="实习生" value="实习生"></el-option>
        <el-option label="专员" value="专员"></el-option>
        <el-option
          label="高级专员（预备）"
          value="高级专员（预备）"
        ></el-option>
        <el-option label="高级专员" value="高级专员"></el-option>
        <el-option label="专家（预备）" value="专家（预备）"></el-option>
        <el-option label="专家" value="专家"></el-option>
        <el-option
          label="高级专家（预备）"
          value="高级专家（预备）"
        ></el-option>
        <el-option label="高级专家" value="高级专家"></el-option>
        <el-option
          label="资深专家（预备）"
          value="资深专家（预备）"
        ></el-option>
        <el-option label="资深专家" value="资深专家"></el-option>
      </el-select>
      <div style="margin-top: 20px">
        <el-button type="primary" @click="addEmployeeTerritory">确认</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showChangeTerritory" title="修改专业职称">
      <h4>职称</h4>
      <div>
        <el-input v-model="employee_territory"></el-input>
      </div>
      <h4>级别</h4>
      <el-select v-model="employee_territory_level" placeholder="请选择">
        <el-option label="实习生" value="实习生"></el-option>
        <el-option label="专员" value="专员"></el-option>
        <el-option
          label="高级专员（预备）"
          value="高级专员（预备）"
        ></el-option>
        <el-option label="高级专员" value="高级专员"></el-option>
        <el-option label="专家（预备）" value="专家（预备）"></el-option>
        <el-option label="专家" value="专家"></el-option>
        <el-option
          label="高级专家（预备）"
          value="高级专家（预备）"
        ></el-option>
        <el-option label="高级专家" value="高级专家"></el-option>
        <el-option
          label="资深专家（预备）"
          value="资深专家（预备）"
        ></el-option>
        <el-option label="资深专家" value="资深专家"></el-option>
      </el-select>
      <div style="margin-top: 20px">
        <el-button type="primary" @click="changeTrueEmployeeTerritory"
          >确认</el-button
        >
      </div>
    </el-dialog>

    <el-dialog :visible.sync="showEquipment" title="添加设备">
      <h4>设备名称</h4>
      <div>
        <el-input v-model="equipment_name"></el-input>
      </div>
      <h4>领取时间</h4>
      <div>
        <el-input v-model="equipment_time"></el-input>
      </div>
      <div style="margin-top: 20px">
        <el-button type="primary" @click="addEquipment">确认</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="showContract" title="添加劳动合同签署">
      <h4>名称</h4>
      <div>
        <el-input v-model="contract_info.name"></el-input>
      </div>

      <h4>签署时间</h4>
      <el-date-picker
        v-model="contract_info.signature_time"
        type="date"
        placeholder="选择日期时间"
        value-format="timestamp"
      >
      </el-date-picker>

      <h4>开始时间</h4>
      <el-date-picker
        v-model="contract_info.begin_time"
        type="date"
        placeholder="选择日期时间"
        value-format="timestamp"
      >
      </el-date-picker>

      <h4>结束时间</h4>
      <el-date-picker
        v-model="contract_info.end_time"
        type="date"
        placeholder="选择日期时间"
        value-format="timestamp"
      >
      </el-date-picker>

      <h4>附件</h4>
      <el-upload
        class="upload-demo"
        action=""
        :limit="3"
        :on-remove="
          (file, fileList) => {
            handleRemove(file, fileList);
          }
        "
        :before-upload="
          (file) => {
            beforeFileUpload(file);
          }
        "
        :file-list="file_list"
      >
        <div class="upload" style="width: 120px" :loading="fileLoading">
          点击上传内容
        </div>
      </el-upload>

      <div style="margin-top: 20px">
        <el-button type="primary" @click="addEmployeeContract">确认</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="showDimission" title="是否要发送企业微信通知全员">
      <h4>离职类型</h4>
      <el-select v-model="dimission_type" placeholder="请选择">
        <!-- <el-option label="个人主动离职" value="个人主动离职"></el-option> -->
        <!-- <el-option label="公司主动解除劳动关系" value="公司主动解除劳动关系"></el-option> -->

        <el-option
          v-for="item in dimission_type_arr"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
      <h4>离职原因</h4>
      <el-select v-model="dimission_info" placeholder="请选择" multiple>
        <!-- <el-option label="个人发展原因" value="个人发展原因"></el-option>
              <el-option label="个人家庭原因" value="个人家庭原因"></el-option>
              <el-option label="工作表现原因" value="工作表现原因"></el-option>
              <el-option label="重大失信行为" value="重大失信行为"></el-option>
              <el-option label="消极工作原因" value="消极工作原因"></el-option>
              <el-option label="工作文化原因" value="工作文化原因"></el-option> -->
        <el-option
          v-for="item in dimission_info_arr"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>

      <div style="margin-top: 20px">
        <el-button type="primary" @click="sendDimissionMessage">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getUser,
  updateUser,
  getWorkLogTemplates,
  removeSuperviserFromUser,
  addSuperviserToUser,
  getUserList,
  getTimeSheetsByUser,
  getUserLastMonthFee,
  getBanks,
  refreshUserPwd,
  updateTerritory,
  addUserEquipment,
  removeUserEquipment,
  getUserEquipments,
  sendAllEmployeeMessage,
  removeTerritory,
  getAssistantDefaultData,
  getUserBUList,
  getEmployeeContract,
  addEmployeeContract,
  removeContract,
  getQiniuToken,
  updateUserHeadPic,
  getUserListWithType,
} from "../../api/assistant";

import UploadHelper from "../../utils/UploadHelper";

export default {
  data() {
    return {
      userInfo: {
        isEmailUser: "NO"
      },
      id: "",
      options: [
        {
          value: "fulltime",
          label: "全职",
        },
        {
          value: "parttime",
          label: "兼职",
        },
      ],
      templates: [],
      supervisers: [],
      territorys: [],
      showAdd: false,
      showAddTerritory: false,
      showEquipment: false,
      showChangeTerritory: false,
      equipments: [],
      equipment_name: "",
      equipment_time: "",
      employee_territory: "",
      employee_territory_level: "",
      editTerritoryIndex: 0,
      selectSuperviser: "",
      users: [],
      userBUs: [],
      sheets: [],
      teammember_ids: [],
      userBUNames: [],
      insurance: "",
      banks: [],
      work_start_time: "",
      attendance_bonus_time: "",
      showDimission: false,
      showContract: false,
      fileUploadHelper: new UploadHelper(),
      file_list: [],
      fileLoading: false,
      fileUploadCallback: undefined,
      fileUploadHeadCallback: undefined,

      contracts: [],
      contract_info: {
        name: "",
        create_time: "",
        begin_time: "",
        signature_time: "",
        end_time: "",
        file: "",
        contract_id: "",
      },
      dimission_info: [],
      dimission_type: "",
      dimission_type_arr: [],
      dimission_info_arr: [],
      countrys: ["中国", "菲律宾"],
      citys: ["上海", "成都", "在家远程"],
      headPicUrl: "",
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getUserList();
    this.getUserBUs();
    this.getWorkLogTemplates();
    this.getTimeSheetsByUser();
    this.getBanks();
    this.getUserEquipments();
    this.getAssistantDefaultData();
    this.getEmployeeContract();
  },
  methods: {
    getUserList() {
      // getUserList().then(res => {
      //   this.users = res.data
      // })

      getUserListWithType("fulltime").then((res) => {
        this.users = res.data;
        this.fetchData();
      });
    },
    getUserBUs() {
      getUserBUList().then((res) => {
        this.userBUs = res.data;
      });
    },
    getBanks() {
      getBanks().then((res) => {
        if (res.data.code === 0) {
          this.banks = res.data.data;
        }
      });
    },
    getUserEquipments() {
      getUserEquipments(this.id).then((res) => {
        this.equipments = res.data;
      });
    },

    getEmployeeContract() {
      getEmployeeContract(this.id).then((res) => {
        this.contracts = res.data;
      });
    },

    fetchData() {
      getUser(this.id).then((res) => {
        if (res.data) {
          this.userInfo = res.data;
          if (res.data.insurance) {
            this.insurance = new Date(res.data.insurance * 1000);
          }
          if (res.data.work_start_time) {
            this.work_start_time = new Date(res.data.work_start_time * 1000);
          }
          if (res.data.attendance_bonus_time) {
            this.attendance_bonus_time = new Date(
              res.data.attendance_bonus_time * 1000
            );
          }
          if (this.userInfo.supervisers) {
            this.supervisers = this.userInfo.supervisers;
          }
          if (this.userInfo.teammember_ids) {
            let user_ids = this.users.map((item) => item._id.$id);
            this.teammember_ids = this.userInfo.teammember_ids.filter((item) =>
              user_ids.includes(item)
            );
          }
          if (this.userInfo.bu_names) {
            this.userBUNames = this.userInfo.bu_names;
          }
          if (this.userInfo.employee_territorys) {
            this.territorys = this.userInfo.employee_territorys;
          }
          if (this.userInfo.headPicUrl) {
            this.headPicUrl = this.userInfo.headPicUrl;
          }
        }
      });
    },
    getAssistantDefaultData() {
      getAssistantDefaultData().then((res) => {
        this.dimission_type_arr = res.data.dimission_type;
        this.dimission_info_arr = res.data.dimission_info;
      });
    },
    getSupervisers() {
      getUser(this.id).then((res) => {
        if (res.data) {
          if (this.userInfo.supervisers) {
            this.supervisers = res.data.supervisers;
          }
        }
      });
    },
    updateUser() {
      updateUser(
        this.userInfo.account_id,
        this.id,
        this.userInfo.user_email,
        this.userInfo.user_pwd,
        this.userInfo.user_lastName,
        this.userInfo.user_givenName,
        this.userInfo.user_employment, // 工作性质
        this.userInfo.wechat_work_id, // 企业微信
        this.userInfo.employee_sheet_template_id,
        this.userInfo.employee_department,
        this.userInfo.employee_active_status,
        this.teammember_ids,
        this.userBUNames,
        this.insurance ? this.insurance.getTime() / 1000 : "",
        this.work_start_time ? this.work_start_time.getTime() / 1000 : "",
        this.attendance_bonus_time
          ? this.attendance_bonus_time.getTime() / 1000
          : "",
        this.userInfo.bank_id,
        this.userInfo.isSmartAdmin,
        this.userInfo.employee_management_territory,
        this.userInfo.employee_management,
        this.userInfo.atHomeWeek,
        this.userInfo.user_country,
        this.userInfo.user_city,
        this.userInfo.employee_trainer,
        this.userInfo.responsibility,
        this.userInfo.user_enName,
        this.userInfo.user_mobile,
        this.userInfo.isEmailUser
      
      ).then((res) => {
        this.$message({
          message: "更新成功",
          type: "success",
        });
        this.fetchData();
        if (this.userInfo.employee_active_status == "离职") {
          this.showDimission = true;
        }
      });
    },
    sendDimissionMessage() {
      if (this.dimission_type == "") {
        this.$message.warning("请选择类型");
        return;
      }
      if (this.dimission_info.length < 1) {
        this.$message.warning("请选择原因");
        return;
      }
      // 句式：因 XXXXXX原因，已于xxx月XX日 离职类型
      var message =
        this.userInfo.user_lastName +
        this.userInfo.user_givenName +
        " " +
        "因" +
        this.dimission_info.toString() +
        "，已于" +
        this.formateDate() +
        " " +
        this.dimission_type;
      // console.log(1111,message)
      sendAllEmployeeMessage(message).then((res) => {
        this.dimission_info = [];
        this.dimission_type = "";
      });
      this.showDimission = false;
    },
    formateDate() {
      const time = new Date();
      return `${time.getMonth() + 1}月${time.getDate()}日`;
    },
    getWorkLogTemplates() {
      getWorkLogTemplates().then((res) => {
        this.templates = res.data;
      });
    },
    add() {
      this.selectSuperviser = "";
      this.showAdd = true;
    },
    addContract() {
      this.showContract = true;

      this.contract_info.contract_id = "";
      this.contract_info.name = "";
      this.contract_info.begin_time = "";
      this.contract_info.end_time = "";
      this.contract_info.signature_time = "";
      this.file_list = [];
    },
    addEmployeeContract() {
      // contract_id,user_id,contract_name,begin_time,end_time,file_url

      addEmployeeContract(
        this.contract_info.contract_id,
        this.id,
        this.contract_info.name,
        this.contract_info.begin_time / 1000,
        this.contract_info.end_time / 1000,
        this.contract_info.signature_time / 1000,
        this.file_list
      ).then((res) => {
        this.getEmployeeContract();
        this.showContract = false;
      });
    },
    beforeFileUpload(file) {
      this.fileLoading = true;
      const that = this;
      this.fileUploadCallback = function (url) {
        that.$message.success("上传成功");
        console.log(111, url);
        var data = {};
        data.name = file.name;
        data.url = url;
        that.file_list.push(data);
        that.fileLoading = false;
      };
      this.fileUploadHelper.addCallback(this.fileUploadCallback);
      this.fileUploadHelper.startUploadForQuestion("", file);
    },

    updateHeadpic(url) {
      console.log(123123, url);
      this.headPicUrl = url;

      updateUserHeadPic(this.id, url).then((res) => {});
    },

    beforeFileUploadHead(file) {
      this.fileLoading = true;
      const that = this;
      this.fileUploadHeadCallback = function (url) {
        that.$message.success("上传成功");
        console.log(111, url);
        that.fileLoading = false;

        that.updateHeadpic(url);
      };
      this.fileUploadHelper.addCallback(this.fileUploadHeadCallback);
      this.fileUploadHelper.startUploadForQuestion("", file);
    },

    handleRemove(file, fileList) {
      this.file_list = fileList.map((item) => {
        return { name: item.name, address: item.address };
      });
    },
    changeContract(row) {
      this.showContract = true;

      this.contract_info.contract_id = row._id.$id;
      this.contract_info.name = row.contract_name;
      this.contract_info.begin_time = row.begin_time * 1000;
      this.contract_info.end_time = row.end_time * 1000;
      this.contract_info.signature_time = row.signature_time * 1000;
      this.file_list = row.file_url;
    },
    removeContract(row) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        removeContract(row).then((res) => {
          this.getEmployeeContract();
        });
      });
    },
    addTerritory() {
      (this.employee_territory = ""),
        (this.employee_territory_level = ""),
        (this.showAddTerritory = true);
    },
    showEquipmentClick() {
      (this.equipment_name = ""),
        (this.equipment_time = ""),
        (this.showEquipment = true);
    },
    addEquipment() {
      addUserEquipment(this.equipment_time, this.equipment_name, this.id).then(
        (res) => {
          this.getUserEquipments();
          this.showEquipment = false;
        }
      );
    },
    deleteEquipment(row) {
      removeUserEquipment(row).then((res) => {
        this.getUserEquipments();
      });
    },
    addEmployeeTerritory() {
      var territory = {};
      territory.employee_territory = this.employee_territory;
      territory.employee_territory_level = this.employee_territory_level;
      this.territorys.push(territory);

      console.log(1111, this.territorys);
      updateTerritory(
        this.id,
        this.territorys,
        this.employee_territory,
        this.employee_territory_level,
        "1"
      ).then((res) => {
        this.$message({
          message: "添加成功",
          type: "success",
        });
        this.showAddTerritory = false;
      });
      // this.showAddTerritory = false
    },
    removeTerritory(index) {
      this.territorys.splice(index, 1);

      removeTerritory(this.id, this.territorys).then((res) => {});
      // removeSuperviserFromUser(this.id,id).then(res=>{
      //   if(res.data.code===0){
      //     this.$message({
      //       message:'删除成功',
      //       type:'success'
      //     })
      //     this.fetchData()
      //   }
      // })
    },
    changeTerritory(index) {
      this.employee_territory = this.territorys[index].employee_territory;
      this.employee_territory_level =
        this.territorys[index].employee_territory_level;
      this.showChangeTerritory = true;
      this.editTerritoryIndex = index;
    },
    changeTrueEmployeeTerritory() {
      this.territorys.splice(this.editTerritoryIndex, 1);

      console.log(
        11111,
        this.employee_territory,
        this.employee_territory_level
      );

      // this.showChangeTerritory = false

      var territory = {};
      territory.employee_territory = this.employee_territory;
      territory.employee_territory_level = this.employee_territory_level;
      this.territorys.push(territory);

      console.log(1111, this.territorys);
      updateTerritory(
        this.id,
        this.territorys,
        this.employee_territory,
        this.employee_territory_level,
        "2"
      ).then((res) => {
        this.$message({
          message: "更新成功",
          type: "success",
        });
        this.showChangeTerritory = false;
      });
    },
    addSuperviserToUser() {
      addSuperviserToUser(this.id, this.selectSuperviser).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.showAdd = false;
          this.getSupervisers();
        }
      });
    },
    remove(id) {
      removeSuperviserFromUser(this.id, id).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.fetchData();
        }
      });
    },
    getTimeSheetsByUser() {
      getTimeSheetsByUser(this.id).then((res) => {
        const sheets = res.data;
        for (const item of sheets) {
          if (item.sheet_template_parameters) {
            item.workContent = [];
            for (const i in item.sheet_template_parameters) {
              const content = {};
              content.key = item.sheet_template_parameters[i];
              content.value = item["sheet_" + i.replace(/ /g, "_")];
              item.workContent.push(content);
            }
          } else {
            item.workContent = [
              {
                key: "工作内容",
                value: item.sheet_work_content,
              },
              {
                key: "明日计划",
                value: item.sheet_work_plan,
              },
              {
                key: "工作感想及建议",
                value: item.sheet_work_comment,
              },
            ];
          }
        }

        this.sheets = sheets;
        console.log(this.sheets);
      });
    },
    formateTime(date) {
      const time = new Date(date * 1000);
      return `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`;
    },
    formateWeek(date){
      let week = this.$dayjs(date*1000).day()
      let weekArr = ['星期日','星期一','星期二','星期三','星期四','星期五','星期六']
      return weekArr[week]
    },
    getUserLastMonthFee() {
      getUserLastMonthFee(this.id).then((res) => {
        console.log(res);
      });
    },
    refreshUserPwd() {
      refreshUserPwd(this.id).then((res) => {
        this.$message.success("重置成功");
      });
    },
    async uploadFile() {
      let file = this.$refs.file.files[0];
      this.name = file.name;
      this.status = true;
      const result = await this.getQiniuToken();
      if (result.data.code === 0) {
        const token = result.data.data;
        let observable = qiniu.upload(file, file.name, token);
        // this.percent = 0
        const observer = {
          next: (res) => {},
          error: (res) => {
            this.$message.warning("上传错误");
          },
          complete: (res) => {
            this.$message.success("上传成功");
            let address = "http://mainbucket.reachable-edu.com/" + res.key;

            console.log(1111, address);
          },
        };
        observable.subscribe(observer);
      } else {
        return;
      }
    },

    async getQiniuToken() {
      return new Promise((resolve) => {
        getQiniuToken().then((res) => {
          resolve(res);
        });
      });
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  color: #606266;
}
</style>
