<template>
  <div>
    <p
      style="padding: 15px; box-sizing: border-box"
      v-if="!selectMail.from && showEmailEditorView == false"
    >
      Please select left email
    </p>
    <div
      v-if="selectMail.from && showEmailEditorView == false"
      style="display: flex; flex-direction: column; height: calc(100vh - 60px)"
    >
      <div
        style="
          background-color: #f5f5f5;
          padding: 10px 15px;
          border-bottom: 1px solid #666;
          box-sizing: border-box;
        "
      >
        <div style="display: flex; align-items: center">
          <p style="flex: 1">Subject: {{ selectMail.subject }}</p>
          <div style="display: flex; column-gap: 2px">
            <div v-if="!selectMail.send_type">
              <el-button
                @click="doNoNeedToReply"
                type="cancel"
                v-if="!selectMail.reply_status || selectMail.reply_status != -1"
                size="small"
                >No Need to Reply</el-button
              >
              <el-button @click="doShowReplyUser" type="success" size="small"
                >Assign Replier</el-button
              >
              <el-button @click="doResetReply" v-if="selectMail.reply_status !=0" type="danger" size="small"
                >Reset Replier </el-button
              >
              <el-button
                @click="doShowReplyView"
                type="primary"
                v-if="!selectMail.reply_status || selectMail.reply_status != -1"
                size="small"
                >Reply</el-button
              >
            </div>
            <div v-if="selectMail.send_type == 'new'">
              <el-button @click="doShowReplyView" type="primary" size="small"
                >Reply</el-button
              >
            </div>
            <el-button v-if="!selectMail.send_type" @click="doAddEmailToBlackList" type="info" size="small"
              >Add BlackList</el-button
            >
            <el-button
              v-if="selectMail.send_type"
              @click="doShowSendAgainView"
              type="primary"
              size="small"
              >Send Again</el-button
            >
            <el-button @click="doRemoveEmail" type="danger" size="small"
              >Delete</el-button
            >
          </div>
        </div>
        <p style="color: #333; margin-top: 5px">
          Sender: {{ selectMail.fromName }} ({{ selectMail.from }})
        </p>
        <p v-if="selectMail.sender" style="color: #333; margin-top: 5px">
          By
          {{
            selectMail.sender.user_lastName +
            " " +
            selectMail.sender.user_givenName
          }}
        </p>
        <p style="color: #333; margin-top: 5px">
          Date: {{ formatMailDate(selectMail) }}
        </p>
        <p style="color: #333; margin-top: 5px">
          Receiver: {{ selectMail.toName }} ({{ selectMail.to }})
        </p>
        <div
          v-if="selectMail.attach_path && selectMail.attach_path != ''"
          style="
            color: #333;
            margin-top: 5px;
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            align-items: center;
          "
        >
          <p style="margin: 0px">Attach:</p>
          <a
            v-for="(path, index) in formatAttachs(selectMail.attach_path)"
            :href="path"
            target="_blank"
            :key="index"
            >{{ formatAttachName(path) }}</a
          >
        </div>
      </div>
      <div style="flex: 1; overflow-y: auto">
        <p
          style="
            color: #666;
            font-size: 14px;
            line-height: 20px;
            padding: 15px;
            margin: 0px;
            box-sizing: border-box;
          "
          v-html="formatEmailContent(selectMail)"
        ></p>
      </div>
    </div>
    <div
      v-if="showEmailEditorView == true"
      style="padding: 15px; box-sizing: border-box"
    >
      <div style="display: flex; margin-bottom: 15px">
        <el-button type="primary" size="small" @click="doSend">Send</el-button>
        <el-button
          type="success"
          size="small"
          @click="doShowAddEmailSignDialog()"
          >Add Email Sign</el-button
        >
        <el-button
          type="cancel"
          size="small"
          @click="showEmailEditorView = false"
          >Cancel</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="formatInputEmailContent()"
          >FormatEmailContent</el-button
        >
      </div>
      <div>
        <el-form label-position="left" label-width="120px">
          <el-form-item
            label="Sender:"
            v-if="sendEmailInfo.send_type == 'reply'"
          >
            <el-input v-model="sendEmailInfo.fromEmail"></el-input>
          </el-form-item>
          <el-form-item label="Sender:" v-if="sendEmailInfo.send_type == 'new'">
            <el-select
              v-model="selectCompanyEmail"
              value-key="_id.$id"
              filterable
              style="width: 500px"
            >
              <el-option
                v-for="(email, index) in emails"
                :key="index"
                :value="email"
                :label="email.email_address + ' (' + email.email_name + ')'"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Receiver:"
            v-if="
              sendEmailInfo.send_type == 'new' &&
              (user_id == '668b7a82a4640b1d4e8b459f' ||
                user_id == '64058bfba4640b3b508b4568')
            "
          >
            <el-select
              v-model="toEmails"
              multiple
              remote
              filterable
              placeholder="Please input contact email or contact name key word to search"
              :loading="loadingSearchContact"
              :remote-method="doSearchContacts"
              style="width: 100%"
            >
              <el-option
                v-for="(contact, index) in contactList"
                :key="index"
                :label="formatContactShowLable(contact)"
                :value="contact.contact_email"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Receiver:" v-else>
            <vue-tags-input
              style="font-size: 16px"
              v-model="toEmail"
              :tags="toEmails"
              placeholder="Please input email"
              @tags-changed="(newTags) => (toEmails = newTags)"
            />
          </el-form-item>
          <el-form-item label="PROJECT BU:">
            <el-select
              v-model="sendEmailInfo.project_BU"
              placeholder="Please select project BU"
              style="width: 100%"
              filterable
              @change="handleBUChanged"
            >
              <el-option
                v-for="(bu, index) in BU_LIST"
                :key="index"
                :label="bu"
                :value="bu"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="PROJECT:">
            <el-select
              v-model="sendEmailInfo.project_id"
              placeholder="Please select project"
              filterable
              style="width: 100%"
            >
              <el-option
                v-for="(project, index) in BU_PROJECTS"
                :key="index"
                :label="formatShowProjectName(project)"
                :value="project._id.$id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Subject:">
            <el-input
              v-model="sendEmailInfo.subject"
              placeholder="Please inut"
            ></el-input>
          </el-form-item>

          <el-form-item label="Carbon Copy:">
            <vue-tags-input
              style="font-size: 16px"
              v-model="ccEmail"
              :tags="ccEmails"
              placeholder="Please input email"
              @tags-changed="(newTags) => (ccEmails = newTags)"
            />
          </el-form-item>

          <el-form-item label="" label-width="0px">
            <div>
              <label>Content</label>
              <div class="editContainer">
                <vue-ueditor-wrap
                  v-model="sendEmailInfo.content"
                  :config="myConfig"
                  @beforeInit="addCustomButtom"
                  class="ueditor-wrap"
                ></vue-ueditor-wrap>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="AI Reply">
            <div>
              <el-button @click="aireply" type="primary" :loading="aibtnloading"
                >AI Reply</el-button
              >
              <el-button
                v-if="relateUserInfoList.length || relateEmailInfoList.length"
                @click="showRe = true"
                >Relate Info</el-button
              >
              <el-button 
                v-if="aiReplyContent"
                @click="useAIReply" 
                type="success"
                >Use AI's Reply</el-button
              >
            </div>
            <div
              style="margin-top: 10px; line-height: 150%"
              v-html="changeToHtml(aiReplyContent)"
            ></div>
          </el-form-item>
          
        </el-form>
      </div>
    </div>

    <el-dialog
      :visible.sync="showAddPicDialog"
      title="Insert Picture"
      width="45%"
    >
      <div>
        <el-form>
          <el-form-item label="Select picture">
            <el-upload
              :before-upload="beforeAddPicUpoad"
              class="avatar-uploader"
              action="#"
            >
              <i class="el-icon-plus avatar-uploader-icon" />
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="showAddVideoDialog"
      title="Insert Video"
      width="45%"
    >
      <div>
        <el-form>
          <el-form-item label="Video link url(https)">
            <el-input
              v-model="videoUrl"
              placeholder="Please input video url"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="margin-top: 20px">
          <el-button @click="showAddVideoDialog = false">Cancel</el-button>
          <el-button type="primary" @click="confirmInsertVideo"
            >Confirm</el-button
          >
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="showAddAudioDialog"
      title="Insert Audio"
      width="45%"
    >
      <div>
        <el-form>
          <el-form-item label="Audio link url (https)">
            <el-input
              v-model="audioUrl"
              placeholder="Please input audio url"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="margin-top: 20px">
          <el-button @click="showAddAudioDialog = false">Cancel</el-button>
          <el-button type="primary" @click="confirmInsertAudio"
            >Confirm</el-button
          >
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="showAddFileDialog"
      title="Insert File"
      width="35%"
    >
      <div>
        <el-form>
          <el-form-item label="Select file">
            <el-upload
              :before-upload="beforeAddFileUpoad"
              :limit="1"
              action="#"
            >
              <el-button size="small" type="primary">click upload</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="showAddEmailSignDialog"
      title="Select Email Sign Template"
      width="55%"
    >
      <div>
        <div
          v-for="(content, index) in mailSignTemplates"
          :key="index"
          class="template-item"
        >
          <p v-html="content" style="flex: 1"></p>
          <el-button
            type="primary"
            size="small"
            @click="selectEmailSignTemplate(content)"
            >Select</el-button
          >
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showAI">
      <el-form>
        <el-form-item label="Matched Agents">
          <el-select style="width: 100%" v-model="selectAgent" filterable>
            <el-option
              v-for="(item, index) in agentList"
              :key="index"
              :label="item.user_email"
              :value="item._id.$id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Voice Input">
          <div style="display: flex; align-items: center;">
            <rtasr
              style="margin-right: 10px"
              @changeValue="changeText"
              @pending="(e) => doPending(1, e)"
              :disabled="pending === 2"
            ></rtasr>
            <rtasr1
              @pending="(e) => doPending(2, e)"
              :disabled="pending === 1"
              style="margin-right: 10px"
              @changeValue="changeText1"
              @saveTemple="saveTemple"
              @clearTemple="clearTemple"
            ></rtasr1>
          </div>
        </el-form-item>
        <el-form-item label="User Instructions on Reply">
          <el-input
            type="textarea"
            v-model="userInstructions"
            :rows="4"
            placeholder="Enter any specific instructions for the AI reply"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="margin-top: 10px; text-align: right">
        <el-button @click="autoAIReply" type="primary">Confirm</el-button>
      </div>
    </el-dialog>
    <el-dialog title="Relate Info" :visible.sync="showRe">
      <el-tabs v-model="relatTab">
        <el-tab-pane label="Agent Info" name="first">
          <div v-for="(item, index) in relateUserInfoList" :key="index">
            <p>{{ item.content }}</p>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Email Info" name="second">
          <div v-for="(item, index) in relateEmailInfoList" :key="index">
            <p>{{ index + 1 }}. {{ item.subject }}</p>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>
<script>
import {
  getEmailMatchClientData,
  removeMail,
  getMail,
  updateMailReplyStatus,
  batchToSendEmail,
  getSingaporeQiniuToken,
  searchContact,
  getMatchAgent,
  getAllAgents,
  getRelatedDataFromVectorBase,
  addMailToBlackList,
  postRequest
} from "../../api/assistant";
import { getUserId } from "../../utils/store";
import VueUeditorWrap from "vue-ueditor-wrap";
import VueTagsInput from "@johmun/vue-tags-input";
import { formatTimestamp, formatDate } from "../../utils/date";
import * as qiniu from "qiniu-js";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import rtasr from "../../components/RealtimeTransfer.vue";
import rtasr1 from "../../components/RealtimeTransfer1.vue";
export default {
  name: "index",
  components: {
    VueUeditorWrap,
    VueTagsInput,
    rtasr,
    rtasr1,
  },

  props: {
    showType: {
      type: String,
      default: ''
    },
    emailSignList: {
      type: Array,
      default: [],
    },
    allProjects: {
      type: Array,
      default: [],
    },
    emails: {
      type: Array,
      default: [],
    },
    mail_id: {
      type: String,
      default: "",
    },
  },
  watch: {
    mail_id: {
      handler(newVal, oldVal) {
        this.requestMailInfo();
      },
      immediate: true,
    },
  },
  data() {
    return {
      relatTab: "first",
      showRe: false,
      aibtnloading: false,
      agentList: [],
      selectAgent: "",
      aiReplyContent: "",
      showAI: false,
      showAddEmailSignDialog: false,
      BU_PROJECTS: [],
      BU_LIST: [
        "SAC",
        "WAO",
        "GYP",
        "Review",
        "ICS",
        "CONF_CAMP",
        "SCH_ACT",
        "PG",
        "FBLA",
        "JEA",
        "Envirothon",
        "GCT",
        "SmallCase",
        "FBLC",
        "FEL",
        "Gocademy",
        "PSB",
        "HTS",
        "NANO",
        "TNEC",
        "AM",
        "CHS",
        "100Institute",
        "PAC",
        "CEO",
        "WEC",
        "IPsyO",
        "SI2",
        "CEC",
        "FinSports",
        "PM",
        "FELC",
        "IBO",
        "GIS",
        "CRR",
        "EI",
        "EAE",
        "NIM",
      ],
      loadingSearchContact: false,
      user_id: getUserId(),
      toEmail: "",
      toEmails: [],

      ccEmail: "",
      ccEmails: [],

      selectMail: {},
      myConfig: {
        UEDITOR_HOME_URL: "/UEditor/",
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 420,
        pasteImage: true, // 是否允许粘贴图片
        autoFloatEnabled: false,
        elementPathEnabled: false,
        initialFrameWith: "100%",
      },
      showAddPicDialog: false,
      showAddAudioDialog: false,
      showAddVideoDialog: false,
      showAddFileDialog: false,
      videoUrl: "",
      audioUrl: "",
      fileUrl: "",
      editorHandler: undefined,
      sendEmailInfo: {
        mail_id: "",
        send_type: "",
        subject: "",
        content: "",
        fromEmail: "",
        fromName: "",
        toEmail: "",
        sender_id: "",
        project_BU: "",
        project_id: "",
        topped: 'NO',
        ccEmails:[]
      },
      showEmailEditorView: false,
      selectCompanyEmail: {},
      contactList: [],
      mailSignTemplates: [],
      isGenerate: false,
      relateUserInfoList: [],
      relateEmailInfoList: [],
      userInstructions: "",
      pending: 0,
      templeContent: "",
    };
  },
  mounted() {
    
  },
  methods: {

    doAddEmailToBlackList(){
      let email = this.selectMail.from
      addMailToBlackList(email).then((res)=>{
        this.$message.success('Add success~')
        this.$emit("refreshCurrentTabEmails");
      })
    },

    formatInputEmailContent() {
      this.sendEmailInfo.content = this.sendEmailInfo.content.replaceAll(
        "\\&quot",
        ""
      );
      this.sendEmailInfo.content = this.sendEmailInfo.content.replaceAll(
        "&quot;",
        '"'
      );
      this.sendEmailInfo.content = this.sendEmailInfo.content.replaceAll(
        "&quot",
        '"'
      );
      this.$message.success("格式化成功");
      this.$forceUpdate();
    },

    changeToHtml(data) {
      return data;
      if (data) {
        if (data.indexOf("```") !== -1) {
          const count = data.split("```").length - 1;
          if (count % 2 === 0) {
            return window.marked.parse(data);
          } else {
            return window.marked.parse(data + "\n\n```");
          }
        } else {
          return window.marked.parse(data);
        }
      } else {
        return "";
      }
    },
    async autoAIReply() {
      this.aibtnloading = true;
      this.aiReplyContent = "";
      this.selectAgent = "";
      this.relateUserInfoList = [];
      this.relateEmailInfoList = [];
      // 提取出this.selectMail.from中的邮箱后缀并去除特殊符号
      let str = this.sendEmailInfo.fromEmail;
      let email_suffix = str.substring(str.lastIndexOf("@") + 1);
      email_suffix = email_suffix.replace(/>/g, "");
      let res1 = await getMatchAgent(email_suffix);
      let selectAgentList = res1.data.data.map((item) => item._id.$id);
      if (selectAgentList.length === 0) {
      } else {
        this.selectAgent = selectAgentList[0];
      }
      let res2 = await getAllAgents();
      this.agentList = res2.data.data;
      let that = this;
      let relateString = "";
      let relateString1 = "";
      let systemMessage;
      let userMessage = {
        role: "user",
        content: `The content of the email is:\n${this.selectMail.content_plain}\n\nUser Instructions: ${this.userInstructions}. You should reply to it instead of taking it as a reference for content repeat.`,
      };

      
      // let res = await getRelatedDataFromVectorBase(
      //   this.selectMail.content_plain,
      //   this.selectAgent,
      //   this.sendEmailInfo.fromEmail
      // );
      // console.log(res);
      // res = res.data;
      // if (res.data && res.data.user && res.data.email) {
      //   for (let item of res.data.user) {
      //     if (item.content) {
      //       relateString += item.content + "\n";
      //     }
      //     this.relateUserInfoList.push(item);
      //   }
      //   for (let item of res.data.email) {
      //     if (item.content) {
      //       relateString1 += item.content + "\n";
      //     }
      //     this.relateEmailInfoList.push(item);
      //   }
      // }

      
      if (this.relateEmailInfoList && this.relateEmailInfoList.length > 0) {
        for (let item of this.relateEmailInfoList) {
          if (item.subject) {
            relateString += 'Subject: ' + item.subject + '\n';
          }
          if (item.content) {
            relateString += 'Content: ' + item.content + '\n\n';
          }
        }
      }


      let userIntro = {
        role: "user",
        content: `Suppose you are ${ this.selectMail.toName} ( ${this.selectMail.to}). Please reply to me with a HTML styled email upon my ask or enquiry. Reply to me HTML texts only (DO not add any extra text or comment before or after the HTML texts. Do NOT use full HTML doc with separate sytle section.). Do NOT return in Markdown style.`,
      };

      let systemConfirmation = {
        role: "system",
        content: `Sure, I will reply to you with a HTML styled email upon your ask or enquiry (not in Markdown style). Let me first find the relevant information, which my replied email will refer to.`,
      };

      let archiveMaterials = {
        role: "system",
        content: `Here are the materials that I have found from our archive. It is important to refer to if I can find relevant answers from them: \n${relateString}`,
      };

      let passEmails = {
        role: "system",
        content: `Here are the emails that I have replied to before. It is important to refer to if I can find similar answers from them: \n${relateString1}`,
      };

      console.log('User Instructions',this.userInstructions)
      let userInstructions = {
        role: "system",
        content: `Here is the User Instructions I received. I should basically reply following these instructions as priority: ${this.userInstructions}.`,
      };

      let userRequest = {
        role: "user",
        content: `Please reply to my email: ${this.selectMail.content_plain}. DO NOT return in Markdown style. Return HTML texts only. DO NOT add any extra text or comment before or after the HTML texts.`,
      };
      systemMessage = {
        role: "system",
        content: `Please help draft a reply to the email based on the following information if they are relevant, or if the following information is not relevant, please respond to the email directly at your best in your own way. DO NOT include use any placeholder text(.e.g [Your name]). Insert a blank line between each two paragraphs. Convert to HMTL style tagged texts. \n${
          relateString || "No related information found"
        }\nHistorically relevant response emails are ${
          relateString1 || "No related information found"
        }\n`,
      };
      // systemMessage = {
      //   role: "system",
      //   content: `Please help draft a reply to the email according to the content of the email (ignore the external links in the email if any)\n`,
      // };
      this.aibtnloading = false;
      const eventSource = fetchEventSource(
        "https://web-backend-sg.reachable-edu.com/AIChat/Test.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          openWhenHidden: true,
          body: JSON.stringify({
            model: "siliconflow/Pro/deepseek-ai/DeepSeek-V3",
            messages: [userIntro, systemConfirmation, archiveMaterials, passEmails, userInstructions, userRequest],
            // messages: [systemMessage, userMessage],
          }),
          onopen(response) {
            console.log("连接已建立", response);
          },
          onmessage(event) {
            if (event.data === "Connection closed") {
              that.isGenerate = false;
              return;
            }
            console.log("收到消息", JSON.stringify(event.data));
            that.isGenerate = true;
            try {
              const result = JSON.parse(event.data);
              if (result.time && result.content) {
                that.aiReplyContent += result.content;
              }
            } catch (error) {
              console.log(error);
            }
          },
          onclose() {},
        }
      );
      this.showAI = false;
    },
    aireply() {
      this.selectAgent = "";
      // 提取出this.selectMail.from中的邮箱后缀并去除特殊符号
      let str = this.sendEmailInfo.fromEmail;
      let email_suffix = str.substring(str.lastIndexOf("@") + 1);
      email_suffix = email_suffix.replace(/>/g, "");
      getMatchAgent(email_suffix).then((res) => {
        let selectAgentList = res.data.data.map((item) => item._id.$id);
        if (selectAgentList.length === 0) {
          this.$message.warning("No matched agents found");
        } else {
          this.selectAgent = selectAgentList[0];
          this.$message.success("Matched agents found");
        }
      });
      getAllAgents().then((res) => {
        this.agentList = res.data.data;
      });
      this.showAI = true;
    },
    async findRelatedEmails() {
      try {
        console.log('Select Email',this.selectMail)
        const response = await postRequest({
          function: 'findRelatedEmails',
          email: this.selectMail.to
        });
        if (response.data) {
          this.relateEmailInfoList =response.data;
        }
        console.log('Related Emails Count',this.relateEmailInfoList.length)
        return [];
      } catch (error) {
        console.error('Error finding related emails:', error);
        return [];
      }
    },
    async confirmSearch() {
      try {
        this.aibtnloading = true;
        this.aiReplyContent = "";
        this.relateUserInfoList = [];
        this.relateEmailInfoList = [];
        let that = this;
        let relateString = "";
        let relateString1 = "";
        let systemMessage;
        let userMessage = {
          role: "user",
          content: `The content of the email is:\n${this.selectMail.content_plain}\n\nUser Instructions: ${this.userInstructions}`,
        };
        const loading = this.$loading({
          lock: true,
          text: "Search the Knowledge Base ...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        // let res = await getRelatedDataFromVectorBase(
        //   this.selectMail.content_plain,
        //   this.selectAgent,
        //   this.sendEmailInfo.fromEmail
        // );
        // loading.close();
        // console.log(res);
        // res = res.data;
        // if (res.data && res.data.user && res.data.email) {
        //   for (let item of res.data.user) {
        //     if (item.content) {
        //       relateString += item.content + "\n";
        //       this.relateUserInfoList.push(item);
        //     }
        //   }
        //   for (let item of res.data.email) {
        //     if (item.content) {
        //       relateString1 += item.content + "\n";
        //       this.relateEmailInfoList.push(item);
        //     }
        //   }
        // }
        systemMessage = {
          role: "system",
          content: `Please help draft a reply to the email based on the following information if they are relevant, or if the following information is not relevant, please respond to the email directly at your best in your own way. DO NOT include use any placeholder text(.e.g [Your name]). Insert a blank line between each two paragraphs.  Convert to HMTL style tagged texts. \n${
            relateString || "No related information found"
          }\nHistorically relevant response emails are ${
            relateString1 || "No related information found"
          }\n`,
        };
        // systemMessage = {
        //   role: "system",
        //   content: `Please help draft a reply to the email according to the content of the email (ignore the external links in the email if any)\n`,
        // };
        this.aibtnloading = false;
        this.showAI = false;
        const eventSource = fetchEventSource(
          "https://web-backend-sg.reachable-edu.com/AIChat/Test.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            openWhenHidden: true,
            body: JSON.stringify({
              model: "gpt-3.5-turbo",
              messages: [systemMessage, userMessage],
            }),
            onopen(response) {
              console.log("连接已建立", response);
            },
            onmessage(event) {
              if (event.data === "Connection closed") {
                that.isGenerate = false;
                return;
              }
              console.log("收到消息", JSON.stringify(event.data));
              that.isGenerate = true;
              try {
                const result = JSON.parse(event.data);
                if (result.time && result.content) {
                  that.aiReplyContent += result.content;
                }
              } catch (error) {
                console.log(error);
              }
            },
            onclose() {},
          }
        );
      } catch (e) {
        this.$message.error("AI reply failed");
        this.aibtnloading = false;
      }
    },

    
    selectEmailSignTemplate(content) {
      this.sendEmailInfo.content += "<br><sss>" + content + "</sss>";
      this.showAddEmailSignDialog = false;
    },

    doShowAddEmailSignDialog() {
      let filter_email = "";
      if (this.sendEmailInfo.send_type == "new") {
        filter_email = this.selectCompanyEmail.email_address;
      } else {
        filter_email = this.sendEmailInfo.fromEmail;
      }
      if (filter_email == "") {
        this.$message.warning("Please input sender email...");
        return;
      }
      this.mailSignTemplates = [];
      for (let i = 0; i < this.emailSignList.length; i++) {
        if (this.emailSignList[i].email == filter_email) {
          this.mailSignTemplates = this.emailSignList[i].templates;
          break;
        }
      }
      if (this.mailSignTemplates.length <= 0) {
        this.$message.warning("No email sign templates found ~");
        return;
      }
      this.showAddEmailSignDialog = true;
    },

    formatShowProjectName(project) {
      if (project.project_ename && project.project_ename != "") {
        return project.project_ename;
      }
      return project.project_name;
    },

    handleBUChanged() {
      this.BU_PROJECTS = [];
      this.sendEmailInfo.project_id = "";
      for (let project of this.allProjects) {
        if (project.project_BU == this.sendEmailInfo.project_BU) {
          this.BU_PROJECTS.push(project);
        }
      }
    },

    formatContactShowLable(contact) {
      let result = contact.contact_email + "(" + contact.contact_name + "-";
      if (contact.contact_position) {
        result += contact.contact_position + "-";
      }
      if (contact.account_country) {
        result += contact.account_country + "-";
      }
      if (contact.account_name) {
        result += contact.account_name;
      }
      result += ")";
      return result;
    },

    doSearchContacts(query) {
      if (query) {
        this.loadingSearchContact = true;
        searchContact(query).then((res) => {
          this.contactList = res.data;
          this.loadingSearchContact = false;
        });
      }
    },

    formatAttachs(attach_path) {
      return attach_path.split(";");
    },
    formatAttachName(url) {
      return url.replace("http://mainbucket.reachable-edu.com/", "");
    },

    doShowSendAgainView() {
      if (
        this.selectMail.assigned_user_id != -1 &&
        this.selectMail.assigned_user_id &&
        this.selectMail.assigned_user_id != getUserId()
      ) {
        this.$message.warning(
          "You don't have the authority to reply this email"
        );
        return;
      }
      this.showEmailEditorView = true;
      let content = this.selectMail.content;
      this.selectCompanyEmail = {};
      let from = this.selectMail.from;
      for (let i = 0; i < this.emails.length; i++) {
        if (this.emails[i].email_address == from) {
          this.selectCompanyEmail = this.emails[i];
          break;
        }
      }
      this.BU_PROJECTS = [];
      this.sendEmailInfo = {
        mail_id: "",
        send_type: "new",
        subject: this.selectMail.subject,
        content: content,
        fromEmail: this.selectMail.from,
        toEmail: this.selectMail.to,
        sender_id: getUserId(),
        project_BU: "",
        project_id: "",
        topped: false,
        ccEmails:[]
      };
      this.ccEmail = "";
      this.ccEmails = [];
      this.toEmail = "";
      this.toEmails = [];
      this.toEmails.push({
        text: this.formatReceiverEmail(this.selectMail.to)
      });
      setTimeout(()=>{
        this.formatInputEmailContent()
      },200)
    },

    refreshEmail() {
      this.requestMailInfo();
    },

    doShowReplyUser() {
      this.$emit("showReplyUserDialog", this.mail_id);
    },

    doRemoveEmail() {
      this.$confirm(
        "Are you sure you want to delete this email?",
        "Delete Email",
        {
          type: "warning",
          cancelButtonText: "Cancel",
          confirmButtonText: "Confirm",
        }
      ).then(() => {
        removeMail(this.mail_id).then((res) => {
          this.$message.success("Delete success!");
          this.$emit("emailDeleted");
        });
      });
    },

    requestMailInfo() {
      this.showEmailEditorView = false;
      if (this.mail_id) {
        getMail(this.mail_id).then((res) => {
          let result = res.data;
          let email = "";
          if (!result.send_type) {
            email = result.from;
          } else {
            email = result.to;
          }
          this.requestClientData(email);
          this.selectMail = result;
          this.sendEmailInfo.topped = result.topped || false;
        });
      } else {
        this.selectMail = {};
      }
    },

    doResetReply() {
      updateMailReplyStatus(this.selectMail._id.$id, 0).then((res) => {
        let result = res.data;
        this.selectMail = result;
        this.$emit("refreshNavSingleEmail", result);
        this.$forceUpdate();
        this.$message.success("操作成功!");
      });
    },

    doNoNeedToReply() {
      updateMailReplyStatus(this.selectMail._id.$id, -1).then((res) => {
        let result = res.data;
        this.selectMail = result;
        this.$emit("refreshNavSingleEmail", result);
        this.$forceUpdate();
        this.$message.success("操作成功!");
      });
    },

    formatEmailContent(email) {
      let content = email.content || email.content_plain;
      content = content.replaceAll("\t", "<br>");
      content = content.replaceAll("text-wrap: nowrap", "white-space: normal");
      // content = content.replaceAll('<img', "<img style=max-width:100%;height:auto;")
      if (
        this.selectMail.reply_emails &&
        this.selectMail.reply_emails.length > 0
      ) {
        for (let i = 0; i < this.selectMail.reply_emails.length; i++) {
          let reply_email = this.selectMail.reply_emails[i];
          let replay_email_format =
            formatTimestamp(reply_email.create_time) +
            " " +
            reply_email.fromName +
            " " +
            reply_email.from +
            " wrote:" +
            "<br><br>" +
            reply_email.content;
          replay_email_format = "<br><xxx>" + replay_email_format + "</xxx>";
          content += replay_email_format;
        }
      }
      return content;
    },

    showContentView() {
      this.showEmailEditorView = false;
    },

    createNewEmail() {
      this.selectCompanyEmail = {};
      this.BU_PROJECTS = [];
      this.sendEmailInfo = {
        mail_id: "",
        send_type: "new",
        subject: "",
        content: "",
        fromEmail: "",
        fromName: "",
        toEmail: "",
        sender_id: getUserId(),
        project_BU: "",
        project_id: "",
        topped: false,
        ccEmails:[]
      };
      this.ccEmail = "";
      this.ccEmails = [];
      this.toEmail = "";
      this.toEmails = [];
      this.showEmailEditorView = true;
    },
    checkIsValidEmail(email){
      if (!email || email == '') {
        return false
      }
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      let result = regex.test(email)
      console.log('checkIsValidEmail->' + email +", result ->"+ result)
      return result
    },
    formatReceiverEmail(email) {
      if (!email || email == '') {
        return email
      }
      const regex = '(.+)<(.+)>'
      const matches = email.match(regex)
      if (matches && matches.length > 2) {
        return matches[2]
      }
      return email
    },
    doSend() {
      if (
        this.sendEmailInfo.send_type == "new" &&
        (this.user_id == "668b7a82a4640b1d4e8b459f" ||
          this.user_id == "64058bfba4640b3b508b4568")
      ) {
        if (this.toEmails.length <= 0) {
          this.$message.warning(
            "Please input contact email or contact name key word to search"
          );
          return;
        }
        this.sendEmailInfo.toEmails = this.toEmails;
      } else {
        let tempToEmails = [];
        if (this.toEmails.length <= 0) {
          this.$message.warning("Please input email");
          return;
        }
        for (var i = 0; i < this.toEmails.length; i++) {
          var email = this.toEmails[i].text;
          if(this.checkIsValidEmail(email) == false){
            console.log('email:' + email)
            this.$message.warning("Please check receiver email");
              return
          }
          tempToEmails.push(email);
        }
        this.sendEmailInfo.toEmails = tempToEmails;
      }
      this.sendEmailInfo.ccEmails = []
      if (this.ccEmails && this.ccEmails.length > 0) {
        for (var i = 0; i < this.ccEmails.length; i++) {
          var email = this.ccEmails[i].text;
          if(this.checkIsValidEmail(email) == false){
            console.log('email1:' + email)
            this.$message.warning("Please check cc email");
              return
          }
          this.sendEmailInfo.ccEmails.push(email)
        }
      }
      if (this.sendEmailInfo.send_type == "new") {
        this.sendEmailInfo.fromEmail = this.selectCompanyEmail.email_address;

        if(this.checkIsValidEmail(this.sendEmailInfo.fromEmail) == false){
          console.log('email2:' + this.sendEmailInfo.fromEmail)
            this.$message.warning("Please check sender email");
              return
          }
        this.sendEmailInfo.fromName = this.selectCompanyEmail.email_name;
      } else {
        if(this.checkIsValidEmail(this.sendEmailInfo.fromEmail) == false){
          console.log('email3:' + this.sendEmailInfo.fromEmail)
            this.$message.warning("Please check sender email");
              return
          }
        this.sendEmailInfo.fromName = this.sendEmailInfo.fromEmail;
        for (let i = 0; i < this.emails.length; i++) {
          if (this.emails[i].email_address == this.sendEmailInfo.fromEmail) {
            this.sendEmailInfo.fromName = this.emails[i].email_name;
            break;
          }
        }
      }
      if (this.sendEmailInfo.project_BU == "") {
        this.$message.warning("Please select project BU");
        return;
      }
      if (this.sendEmailInfo.project_id == "") {
        this.$message.warning("Please select project");
        return;
      }
      let emailContent = this.sendEmailInfo.content.replaceAll("\t", "");
      this.sendEmailInfo.mail_content = emailContent;
      this.sendEmailInfo.content = emailContent;
      let content = emailContent;
      let pos = content.search("<xxx>");
      if ([pos != -1]) {
        let newContent = content.substring(0, pos);
        if (newContent && newContent.length > 0) {
          this.sendEmailInfo.content = newContent;
        }
      }
      if (false) {
        this.$message.success('可以发送邮件～')
        console.log("info :" + JSON.stringify(this.sendEmailInfo));
        return;
      }
      batchToSendEmail(this.sendEmailInfo).then((res) => {
        let result = res.data;
        let errorCount = result["failure"];
        if (errorCount > 0) {
          this.$message.error(
            "Email Sended, Success Count " +
              result["success"] +
              " Failured Count " +
              errorCount
          );
        } else {
          this.$message.success(
            "Email Sended, Success Count " +
              result["success"] +
              " Failured Count " +
              errorCount
          );
        }
        this.showEmailEditorView = false;
        if (this.sendEmailInfo.send_type == "reply") {
          let reply_status = 1;
          if (this.selectMail.reply_status) {
            reply_status = this.selectMail.reply_status + 1;
          }
          this.selectMail.reply_status = reply_status;
          //更新状态
          updateMailReplyStatus(this.selectMail._id.$id, reply_status).then(
            (res) => {}
          );
        }
        this.$emit("refreshEmails");
      });
    },

    doShowReplyView() {
      if (
        this.selectMail.assigned_user_id &&
        this.selectMail.assigned_user_id != -1 &&
        this.selectMail.assigned_user_id != getUserId()
      ) {
        this.$message.warning(
          "You don't have the authority to reply this email"
        );
        return;
      }

      
      this.showEmailEditorView = true;
      this.findRelatedEmails();
      this.userInstructions = "";
      let content = this.selectMail.content;
      if (content) {
        let receive_email_format =
          formatTimestamp(this.selectMail.create_time) +
          " " +
          this.selectMail.fromName +
          " " +
          this.selectMail.from +
          " wrote:" +
          "<br><br>" +
          this.selectMail.content;
        content = "<br><br><br><xxx>" + receive_email_format + "</xxx>";

        for (let i = 0; i < this.selectMail.reply_emails.length; i++) {
          let reply_email = this.selectMail.reply_emails[i];
          let replay_email_format =
            formatTimestamp(reply_email.create_time) +
            " " +
            reply_email.fromName +
            " " +
            reply_email.from +
            " wrote:" +
            "<br><br>" +
            reply_email.content;
          // let replay_email_format = "<p style='background-color: #f5f5f5;color: #999;display: inline-block;'>" + formatTimestamp(reply_email.create_time) + " " + reply_email.fromName + " " + reply_email.from + " wrote:</p>" + "<br><br>" + reply_email.content
          replay_email_format = "<br><xxx>" + replay_email_format + "</xxx>";
          content += replay_email_format;
        }
      }
      this.selectCompanyEmail = {};
      this.sendEmailInfo = {
        mail_id: this.selectMail._id.$id,
        send_type: "reply",
        subject: "REPLY:" + this.selectMail.subject,
        content: content,
        fromEmail: this.selectMail.to,
        toEmail: this.selectMail.from,
        sender_id: getUserId(),
        project_BU: "",
        project_id: "",
        topped: this.selectMail.topped,
        ccEmails:[]
      };

      this.BU_PROJECTS = [];
      this.ccEmail = "";
      this.ccEmails = [];
      this.toEmail = "";
      this.toEmails = [];

      if (this.selectMail.send_type == "new") {
        this.sendEmailInfo.fromEmail = this.selectMail.from;
        this.sendEmailInfo.toEmail = "";
      } else {
        this.toEmails.push({
          text: this.formatReceiverEmail(this.selectMail.from),
        });
      }
      setTimeout(()=>{
        this.formatInputEmailContent()
      },200)
      try {
        this.autoAIReply();
      } catch (e) {
        this.$message.error("AI reply failed");
        this.aibtnloading = false;
      }
    },
    formatMailDate(mail) {
      const create_time = mail.create_time;
      const date = new Date(create_time * 1000);
      const utc = date.getTime() + date.getTimezoneOffset() * 60000;
      const nd = new Date(utc + 8 * 60 * 60 * 1000);
      return formatDate(nd, "yyyy-MM-dd hh:mm:ss");
    },
    confirmInsertAudio() {
      let html =
        "<audio controls> <source src=" +
        this.audioUrl +
        ' type="audio/mp3"></audio>';
      this.editorHandler.execCommand("insertHTML", html);
      this.showAddAudioDialog = false;
    },
    confirmInsertVideo() {
      let link = `<a href=${this.videoUrl} target='_blank'>点击播放视频</a>`;
      let video = `<video height=${240} width=${300} src="${
        this.videoUrl
      }" autoplay loop controls="controls"></video>`;
      let html = video + "<br>" + link;
      this.editorHandler.execCommand("insertHTML", html);
      this.showAddVideoDialog = false;
    },
    async beforeAddFileUpoad(file) {
      const result = await getSingaporeQiniuToken();
      let time = new Date().getTime();
      let file_name = "Email_Attachment_" + time;
      if (result.data.code === 0) {
        const token = result.data.data;
        this.observable = qiniu.upload(file, file_name, token);
        const observer = {
          complete: (res) => {
            let url = "https://ap.learningfirst.tech/" + res.key;
            let link = `<a href=${url} target='_blank'>${file.name}</a>`;
            this.showAddFileDialog = false;
            this.editorHandler.execCommand("inserthtml", link);
          },
        };
        this.observable.subscribe(observer);
      }
    },

    async beforeAddPicUpoad(file) {
      const result = await getSingaporeQiniuToken();
      if (result.data.code === 0) {
        const token = result.data.data;
        this.observable = qiniu.upload(
          file,
          new Date().getTime() + file.name,
          token
        );
        const observer = {
          complete: (res) => {
            let url = "https://ap.learningfirst.tech/" + res.key;
            let imageHtml = '<p><img src="' + url + '"/></p>';
            this.showAddPicDialog = false;
            this.editorHandler.execCommand("inserthtml", imageHtml);
          },
        };
        this.observable.subscribe(observer);
      }
    },

    addCustomButtom(editorId) {
      let that = this;

      window.UE.registerUI(
        "autoaudio",
        (editor, uname) => {
          const btnAudio = new window.UE.ui.Button({
            name: "my-audio",
            title: "上传音频",
            cssRules:
              "background-image: url('https://mainbucket.learningfirst.cn/icon/icon_audio.png') !important;background-size: cover",
            onclick: function () {
              that.editorHandler = editor;
              that.showAddAudioDialog = true;
            },
          });
          return btnAudio;
        },
        0,
        editorId
      );

      window.UE.registerUI(
        "autoimg",
        (editor, uname) => {
          const btnImg = new window.UE.ui.Button({
            name: "my-img",
            title: "上传图片",
            cssRules:
              "background-image: url('https://mainbucket.learningfirst.cn/icon/icon_image.png') !important;background-size: cover",
            onclick: function () {
              that.editorHandler = editor;
              that.showAddPicDialog = true;
            },
          });
          return btnImg;
        },
        0,
        editorId
      );

      window.UE.registerUI(
        "autovideo",
        (editor, uname) => {
          const btnVideo = new window.UE.ui.Button({
            name: "my-video",
            title: "上传视频",
            cssRules:
              "background-image: url('https://mainbucket.learningfirst.cn/icon/icon_video.png') !important;background-size: cover",
            onclick: function () {
              that.editorHandler = editor;
              that.showAddVideoDialog = true;
            },
          });
          return btnVideo;
        },
        0,
        editorId
      );

      window.UE.registerUI(
        "autofile",
        (editor, uname) => {
          const btnFile = new window.UE.ui.Button({
            name: "my-file",
            title: "上传文件",
            cssRules:
              "background-image: url('https://mainbucket.learningfirst.cn/icon/icon_file.png') !important;background-size: cover;",
            onclick: function () {
              that.editorHandler = editor;
              that.showAddFileDialog = true;
            },
          });
          return btnFile;
        },
        0,
        editorId
      );
    },
    requestClientData(email) {
      getEmailMatchClientData(email).then((res) => {
        let clientDatas = res.data;
        this.$emit("clientDatasCallback", clientDatas);
      });
    },
    useAIReply() {
      // Find the position of the first <xxx> tag which contains the quoted history
        console.log(this.aiReplyContent)
      let pos = this.sendEmailInfo.content.search("<xxx>");
      if (pos !== -1) {
        // Get the history content after the <xxx> tag
        let historyContent = this.sendEmailInfo.content.substring(pos);
        // Remove the history content from the original content
        
        // Insert AI reply before the quoted history
        // Convert newlines to HTML line breaks
        let htmlContent = this.aiReplyContent.replace(/\n/g, '<br>');
        this.sendEmailInfo.content = htmlContent + "<br><br><br>" + historyContent;
      } else {
        // If no history found, just set the AI reply
        this.sendEmailInfo.content = this.aiReplyContent;
      }
      this.$message.success('AI reply content inserted');
    },
    changeText(text) {
      this.userInstructions = text;
    },
    changeText1(text) {
      this.userInstructions = text;
    },
    doPending(type, e) {
      if (e) {
        this.pending = type;
      } else {
        this.pending = 0;
      }
    },
    saveTemple() {
      this.templeContent = this.userInstructions;
    },
    clearTemple() {
      this.templeContent = "";
    },
  },
};
</script>
<style>
.ti-tag {
  background-color: #ff6450 !important;
  /* 您想要的颜色 */
}

.ti-input {
  border-radius: 5px;
}
</style>

<style scoped lang="scss">
.template-item {
  align-items: flex-start;
  display: flex;
  width: 100%;
  padding: 6px 12px;
  box-sizing: border-box;
  margin-bottom: 10px;
  color: #333;
  font-size: 14px;
  background-color: #ffd9d4;
  border-radius: 5px;
}

::-webkit-scrollbar {
  display: none;
}

::v-deep .ueditor-wrap {
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  word-break: break-all !important;
}

.editContainer {
  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 600px;

  .editor {
    height: 100%;
  }

  .btnGroup {
    width: 100px;
    flex-shrink: 0;
    margin-left: 10px;
    display: block;
  }
}

.avatar-uploader {
  width: 178px;
  height: 178px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  object-fit: cover;
  width: 178px;
  height: 178px;
  display: block;
}
</style>