<template>
    <div class="left-aside">
        <div style="margin: 10px 0px;">
            <el-select v-if="type == 'in'" style="width: 95%;margin-bottom: 15px;" v-model="selectAssignedUser"
                @change="handleSelectAssignedUserChanged" :clearable="true" placeholder="Please select assigned user">
                <el-option v-for="(assigned_user, index) in operators" :key="index" :value="assigned_user._id.$id"
                    :label="(assigned_user?.user_lastName + ' ' + assigned_user?.user_givenName)"></el-option>
            </el-select>
            <el-select v-if="type == 'out'" style="width: 95%;margin-bottom: 15px;" v-model="selectSender"
                @change="handleOperatorChanged" :clearable="true" placeholder="Please select operator">
                <el-option v-for="(sender, index) in operators" :key="index" :value="sender._id.$id"
                    :label="(sender?.user_lastName + ' ' + sender?.user_givenName)"></el-option>
            </el-select>
            <el-select style="width: 95%;" v-model="filterEmail" @change="handleSelectFilterEmailChanged"
                :clearable="true"
                :placeholder="type == 'in' ? 'Please select receiver email' : 'Please select sender email'">
                <el-option v-for="(email, index) in companyEmails" :key="index" :value="email.email_address"
                    :label="email.email_address"></el-option>
            </el-select>
            <div v-if="type == 'in'" style="display: flex;align-items: center;margin-top: 10px;">
                <p style="font-weight: bold;font-size: 14px;color: #4a8af4"> Reply Status </p>
                <el-radio-group style="margin-left: 15px;margin-top: 5px;" v-model="selectReplyStatus"
                    @change="handleReplyStatusChanged">
                    <el-radio label="all">All</el-radio>
                    <el-radio label="pending" style="margin-left: 10px;">Pending Reply</el-radio>
                </el-radio-group>
            </div>
        </div>
        <div style="flex: 1;overflow-y: auto;">
            <div v-for="(item, index) in showDataList" :key="index"
                :class="selectMailIndex == index ? 'nav-item nav-item-selected' : 'nav-item'"
                @click="handleSelectMail(item, index)">
                <div style="text-align: right;margin-bottom: 0px;" v-if="type == 'in'">
                    <p class="reply" :style="createReplyStatusStyle(item)">
                        {{ formatReplyStatus(item) }} </p>
                </div>
                <div style="text-align: right;margin-bottom: 0px;" v-if="type == 'out'">
                    <p v-if="item.send_result == 'success'" class="reply"
                        :style="{ color: item.open_status && item.open_status == true ? '#409EFF' : '#E6A23C' }">
                        {{ item.open_status && item.open_status == true ? 'Opened' : 'Not Open' }}
                    </p>
                    <p v-else class="reply" :style="{ color: '#F56C6C' }">
                        {{ 'Failured' }}
                    </p>
                </div>

                <p class="email">Sender: {{ item.fromName }} ({{ item.from }})</p>
                <p class="time" v-if="type == 'out'" style="margin-top: 5px;">By {{ item.sender?.user_lastName + " " +
                    item.sender?.user_givenName }}</p>

                <p class="time">Date: {{ formatMailDate(item) }}</p>
                <p class="email">Receiver: {{ item.to }}</p>
                <div v-if="item.assigned_user_id">
                    <p class="email" style="margin-top: 5px;color:blue" v-if="item.assigned_user_id != -1">Assigned to :
                        {{
                            item.assigned_user?.user_lastName + " " +
                            item.assigned_user?.user_givenName }}</p>
                    <p class="email" style="margin-top: 5px;color:blue" v-else>Not Assigned</p>
                </div>
                <p class="subject">Subject: {{ item.subject }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import {
    getCompanyEmailsV1
} from '../../api/assistant'
import {
    formatDate
} from '../../utils/date'
import Cookies from 'js-cookie'
export default ({
    name: 'index',
    props: {
        operators: {
            type: Array,
            default: []
        },
        type: {
            type: String,
            default: ''
        },
        emails: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            selectAssignedUser: '',//收件箱
            selectSender: '',//发件箱
            selectReplyStatus: 'all',//收件箱
            filterEmail: '',//发，收都用
            filterEmails: [],
            dataList: [],
            showDataList: [],
            companyEmails: [],
            selectMailIndex: -1,
            currentUser: {
                user_lastName: '',
                user_givenName: '',
                user_enName: ''
            },
            isLoading: false,
            error: null
        }
    },
    watch: {
        emails: {
            handler(newVal, oldVal) {
                this.handleEmailsData(newVal)
            },
            immediate: true
        },
    },
    mounted() {
        this.requestCompanyEmails()
    },
    methods: {

        refreshSingleMail(mail){
            for(let i=0;i<this.dataList.length;i++){
                if(this.dataList[i]._id.$id == mail._id.$id){
                    this.dataList[i] = mail
                    break
                }
            }
            for(let i=0;i<this.showDataList.length;i++){
                if(this.showDataList[i]._id.$id == mail._id.$id){
                    this.showDataList[i] = mail
                    break
                }
            }
            this.$nextTick(()=>{
                this.$forceUpdate()
            })
        },

        //收件箱使用
        handleSelectAssignedUserChanged(e) {
            this.doFilterEmail()
        },

        //发件箱使用
        handleOperatorChanged(e) {
            this.doFilterEmail()
        },

        resetSelectMailIndex() {
            this.selectMailIndex = -1
        },
        //收件箱使用
        handleReplyStatusChanged(e) {
            this.doFilterEmail()
        },


        formatReplyStatus(mail) {
            if (mail.reply_status) {
                if (mail.reply_status == -1) {
                    return 'No Need to Reply'
                }
                if (mail.reply_status > 0) {
                    return 'Replied'
                }
            }
            return 'Pending Reply'
        },

        createReplyStatusStyle(mail) {
            let style = { //默认是没有的，需要回复
                color: "red"
            }
            if (mail.reply_status) {//有
                if (mail.reply_status == -1) {
                    style.color = '#666'
                }
                if (mail.reply_status > 0) {
                    style.color = 'green'
                }
            }
            return style
        },

        async requestCompanyEmails() {
            this.isLoading = true
            this.error = null
            try {
                const response = await getCompanyEmailsV1()
                this.companyEmails = response.data 
                //if (response.data && response.data.user) {
                //    this.currentUser = response.data.user
                //}
                //if (response.data && response.data.emails) {
                //    this.companyEmails = response.data.emails.map(email => ({
                //        ...email,
                //        user: email.user || {
                //            user_lastName: '',
                //            user_givenName: '',
                //            user_enName: ''
                //        }
                //    }))
                //} else {
                //    this.companyEmails = []
                //    console.warn('No emails data in response:', response)
                //}
            } catch (error) {
                console.error('Error fetching company emails:', error)
                this.error = error.message
                this.$message.error('获取公司邮件列表失败')
            } finally {
                this.isLoading = false
            }
        },

        handleSelectFilterEmailChanged(e) {
            this.doFilterEmail()
        },

        handleEmailsData() {
            this.filterEmail = ''
            this.dataList = []
            this.showDataList = []

            if (this.emails && this.emails.length > 0) {
                this.emails.map((item) => {
                    this.dataList.push(item)
                    this.showDataList.push(item)
                })
            }
            this.restoreFilterParams()
            this.doFilterEmail()
        },

        formatMailDate(mail) {
            const create_time = mail.create_time
            const date = new Date(create_time * 1000)
            const utc = date.getTime() + date.getTimezoneOffset() * 60000
            const nd = new Date(utc + 8 * 60 * 60 * 1000)
            return formatDate(nd, 'yyyy-MM-dd hh:mm:ss')
        },
        handleSelectMail(email, index) {
            this.selectMailIndex = index
            if (this.type == 'in') {
                this.$emit('handleInSelectMail', email)
            } else {
                this.$emit('handleOutSelectMail', email)
            }
        },


        doFilterEmail() {
            if (this.checkShowAll()) {
                this.showDataList = this.dataList
                return
            }
            this.showDataList = []
            for (let i = 0; i < this.dataList.length; i++) {
                let mail = this.dataList[i]
                if (this.checkFilterMail(mail)) {
                    this.showDataList.push(mail)
                }
            }
        },

        checkFilterMail(mail) {
            console.log('mail :' + JSON.stringify(mail))
            if (this.type == 'in') {//收件箱
                let enableAssignedUser = false
                let enableFilterEmail = false
                let enableRepyStatus = false

                if (this.selectAssignedUser == '') {//全部
                    enableAssignedUser = true
                } else {
                    if (mail.assigned_user_id && this.selectAssignedUser == mail.assigned_user_id) {
                        enableAssignedUser = true
                    }
                }
                if (this.filterEmail == '' || this.filterEmail == 'All') {
                    enableFilterEmail = true
                } else {
                let email = mail.to
                    if (email && email.indexOf(this.filterEmail.trim())>=0) {
                        enableFilterEmail = true
                    }
                }

                if (this.selectReplyStatus == 'all') {//全部
                    enableRepyStatus = true
                } else {
                    if (!mail.reply_status || mail.reply_status == 0) {
                        enableRepyStatus = true
                    }
                }
                if (enableAssignedUser == true && enableFilterEmail == true && enableRepyStatus == true) {
                    return true
                }
                return false
            } else { //发件箱
                let enableSender = false
                let enableFilterEmail = false
                if (this.selectSender == '') {
                    enableSender = true
                } else {
                    if (mail.sender_id == this.selectSender) {
                        enableSender = true
                    }
                }
                if (this.filterEmail == '' || this.filterEmail == 'All') {
                    enableFilterEmail = true
                } else {
                    let email = mail.from
                    if (email && email.indexOf(this.filterEmail.trim()) >= 0) {
                        enableFilterEmail = true
                    }
                }
                if (enableSender == true && enableFilterEmail == true) {
                    return true
                }
                return false
            }
        },
        checkShowAll() {
            if (this.type == 'in') {
                if (this.selectAssignedUser == '' && (this.filterEmail == '' || this.filterEmail == 'All') && this.selectReplyStatus == 'all') {
                    return true
                }
                return false
            } else {
                if ((this.selectSender == '' || this.selectSender == undefined) && (this.filterEmail == '' || this.filterEmail == 'All')) {
                    return true
                }
                return false
            }
        },

        saveFilterParams() {
            Cookies.set('selectAssignedUser', this.selectAssignedUser)
            Cookies.set('filterEmail', this.filterEmail)
            Cookies.set('selectReplyStatus', this.selectReplyStatus)
            Cookies.set('selectSender', this.selectSender)
        },
        restoreFilterParams() {
            this.selectAssignedUser = Cookies.get('selectAssignedUser') ? Cookies.get('selectAssignedUser') : ''
            this.filterEmail = Cookies.get('filterEmail') ? Cookies.get('filterEmail') : ''
            this.selectReplyStatus = Cookies.get('selectReplyStatus') ? Cookies.get('selectReplyStatus') : ''
            this.selectSender = Cookies.get('selectSender') ? Cookies.get('selectSender') : ''

            Cookies.set('selectAssignedUser', '')
            Cookies.set('filterEmail', '')
            Cookies.set('selectReplyStatus', 'all')
            Cookies.set('selectSender', '')
        }
    }
})
</script>
<style scoped lang="scss">
.left-aside {
    height: calc(100vh - 60px);
    display: flex;
    background-color: white;
    flex-direction: column;
    border-right: 1px solid #f5f5f5;

    .nav-item {
        cursor: pointer;
        padding: 10px 15px;
        box-sizing: border-box;
        width: 100%;
        color: #333;
        font-size: 16px;
        background-color: white;
        border-bottom: 1px solid #f5f5f5;

        .reply {
            display: inline-block;
            margin: 0px;
            font-size: 10px;
            color: red;
        }


        .email {
            font-size: 12px;
            color: #333;
            font-weight: bold;
            margin: 0px;
        }

        .time {
            margin-top: 5px;
            margin-bottom: 5px;
            color: #666;
            font-size: 12px;
        }

        .subject {
            margin: 5px 0px;
            color: #666;
            font-size: 12px;
        }

        &:hover {
            background-color: #f5f5f5;
        }
    }

    .nav-item-selected {
        background-color: #FFD9D4;

        &:hover {
            background-color: #FFD9D4;
        }
    }

    .nav-item:last-child {
        margin-bottom: 40px;
    }
}
</style>